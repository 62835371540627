import React, {useEffect, useState} from 'react';
import {Typography, Grid, Badge, IconButton} from '@mui/material';
import LineChartComponent from "./LineChartComponent"; // or another charting library
import {Line} from "react-chartjs-2";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';

interface Metric {
    label: string;
    value: number;
    graphData: any[]; // Define a specific type for your graph data
}

function getTrendColor(data: any[]): string {
    const lastPoint = data[data.length - 1];
    const secondLastPoint = data[data.length - 2];
    return lastPoint > secondLastPoint ? 'rgba(0, 255, 0, 0.2)' : 'rgba(255, 0, 0, 0.2)';
}

const convertCamelCaseToWords = (str: string) => {
    return str.replace(/([A-Z])/g, " $1").replace(/^./, function (str) { return str.toUpperCase(); });
};

function Metrics() {

    const [loading, setLoading] = useState(true);
    const [avgExceptionsPer1000Miles, setAvgExceptionsPer1000Miles] = useState<{ [key: string]: number }>({});

    // Function to get darker shade for line color
    const getDarkerShade = (color: any, shadeFactor = 0.3) => {
        // Extract the R, G, B, A values from the color
        const rgba = color.match(/[\d.]+/g).map(Number);

        // Apply the shadeFactor to the R, G, B values
        rgba[0] = Math.floor(rgba[0] * shadeFactor);
        rgba[1] = Math.floor(rgba[1] * shadeFactor);
        rgba[2] = Math.floor(rgba[2] * shadeFactor);

        // Construct the new color
        return `rgba(${rgba[0]}, ${rgba[1]}, ${rgba[2]}, ${rgba[3]})`;
    };

    // Example usage
    const badgeColors = [
        "rgba(0, 123, 255, 0.2)",
        "rgba(0, 255, 0, 0.2)",
        "rgba(255, 255, 0, 0.2)",
        "rgba(255, 0, 0, 0.2)"
    ];

    const darkerBadgeColors = badgeColors.map(color => getDarkerShade(color));

    const [realMetrics, setRealMetrics] = useState<Metric[]>([]);
    const [selectedMetric, setSelectedMetric] = useState<Metric | null>(null);

    const handleBadgeClick = (metric: Metric) => {
        setSelectedMetric(metric);
    };

    useEffect(() => {
        let url = "https://33gzciyeecjqoxpldah3qeku4q0iddjy.lambda-url.us-east-1.on.aws/";
        setLoading(true);
        axios.get(url).then((response) => {
            const realMetrics = Object.keys(response.data.chartData).map(key => {
                const chartData = response.data.chartData[key];
                return {
                    label: convertCamelCaseToWords(key),
                    value: chartData[chartData.length - 1][1],
                    graphData: chartData,
                };
            });

            // Update the state with the new metrics data
            setRealMetrics(realMetrics);
            setAvgExceptionsPer1000Miles(response.data.avgExceptionsPer1000Miles);

            setLoading(false);
        }).catch((error) => {
            console.error(error);
            setLoading(false);
        });
    }, []);


    return (
        <div>
            {loading ? (
                <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '100vh' }}>
                    <CircularProgress />
                </Grid>
            ) : (
                <>
                    {/* New Badges for Daily Averages */}
                    <Grid container justifyContent="center" style={{ padding: '20px', maxWidth: '90%', margin: '0 auto' }}>
                        <div style={{ position: 'relative', display: 'inline-block' }}>
                            <Typography variant="h4">
                                Averages<Tooltip placement='top' arrow={true} title="The average is based on the cumulative average exceptions per 1000 miles for all accounts.">
                                    <IconButton
                                        aria-label="averages"
                                        style={{
                                            padding: 0,
                                            position: 'absolute',
                                            top: 0,
                                            color: 'rgba(0, 123, 255, 1)'
                                        }}
                                    >
                                        <InfoIcon fontSize="small" />  {/* Use 'small' to make icon smaller */}
                                    </IconButton>
                                </Tooltip>
                            </Typography>

                        </div>

                        <Grid container spacing={3} justifyContent="center">
                            <Grid item xs={12} sm={12} md={12}>
                                <div style={{
                                    border: '1px solid #000',
                                    borderRadius: '5px',
                                    padding: '10px',
                                    textAlign: 'left',
                                    backgroundColor: 'rgba(0, 123, 255, 0.2)',
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    justifyContent: 'space-around',
                                    marginLeft: '24px'
                                }}>
                                    {Object.keys(avgExceptionsPer1000Miles).map((key, index) => (
                                        <div key={index} style={{ minWidth: '15%', marginBottom: '10px', textAlign: 'center' }}>
                                            <Typography variant="subtitle1">
                                                {key.replace(/(total_)/, '').replace(/(_exceptions)/, '').split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                                            </Typography>
                                            <Typography variant="h4">  {/* Increase the size to h4 */}
                                                {avgExceptionsPer1000Miles[key]}
                                            </Typography>
                                        </div>
                                    ))}
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} justifyContent="center" style={{ padding: '20px', maxWidth: '90%', margin: '0 auto' }}>
                    {realMetrics.map((metric, index) => {
                        const backgroundColor = "rgba(0, 123, 255, 0.2)";
                        const lineColor = getDarkerShade("rgba(0, 123, 255, 0.2)");

                        const lastValue = metric.graphData[metric.graphData.length - 1][1] as number;
                        const secondLastValue = metric.graphData.length > 1 ? metric.graphData[metric.graphData.length - 2][1] as number : null;
                        const percentChange = secondLastValue ? ((lastValue - secondLastValue) / secondLastValue) * 100 : 0;


                        return (
                            <Grid item xs={12} sm={6} md={3} key={index}>
                                <div style={{
                                    border: '1px solid #000',
                                    borderRadius: '5px',
                                    cursor: 'pointer',
                                    padding: '10px',
                                    textAlign: 'left',
                                    position: 'relative',
                                    height: '200px',
                                    backgroundColor
                                }} onClick={() => handleBadgeClick(metric)}>
                                    <div style={{
                                        zIndex: 1,
                                        position: 'relative',
                                        paddingBottom: '10px',
                                        color: 'black'
                                    }}>
                                        <Typography variant="subtitle1">{metric.label} </Typography>
                                        <Typography variant="h5">
                                            {metric.value}{' '}
                                            <span style={{fontSize: '0.8em'}}>
                                                    {percentChange > 0 ? (
                                                        <span>
                                                            (+{percentChange % 1 === 0 ? percentChange : percentChange.toFixed(1)}% <ArrowUpwardIcon
                                                            fontSize="inherit"/>)
                                                        </span>
                                                    ) : (
                                                        <span>
                                                            ({Math.abs(percentChange) % 1 === 0 ? Math.abs(percentChange) : Math.abs(percentChange).toFixed(1)}% <ArrowDownwardIcon
                                                            fontSize="inherit"/>)
                                                        </span>
                                                    )}
                                                </span>
                                        </Typography>
                                    </div>
                                    <Line
                                        data={{
                                            labels: metric.graphData.map((item) => item[0] as string),
                                            datasets: [
                                                {
                                                    label: "",
                                                    backgroundColor: "transparent",
                                                    borderColor: lineColor,
                                                    borderWidth: 1,
                                                    pointBackgroundColor: lineColor,
                                                    data: metric.graphData.map((item) => item[1] as number),
                                                    pointRadius: 3,
                                                    pointHoverRadius: 5,
                                                },
                                            ],
                                        }}
                                        options={{
                                            animation: {duration: 0},
                                            plugins: {legend: {display: false}},
                                            scales: {
                                                x: {display: false, offset: false},
                                                y: {display: false},
                                            },
                                        }}
                                        style={{
                                            zIndex: 2,
                                            position: 'absolute',
                                            bottom: 0,
                                            left: 0,
                                            right: 0,
                                            height: '100%',
                                            width: '100%'
                                        }}/>
                                </div>
                            </Grid>
                        );
                    })}
                </Grid>
                    <div style={{ width: '90%', height: '500px', margin: '0 auto' }}>
                    {selectedMetric && (
                            <div>
                                <Typography variant="h5">{selectedMetric.label}</Typography>
                                <LineChartComponent data={selectedMetric.graphData} title={selectedMetric.label}/>
                            </div>
                        )}
                    </div>
                </>)}
        </div>
    );
}

export default Metrics;
