import * as React from 'react';
import {useReducer} from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {Grid, TextField} from "@mui/material";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


// @ts-ignore
export default function AddUserModal({handleAddUser}) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [formInput, setFormInput] = useReducer(
        (state: any, newState: any) => ({ ...state, ...newState }),
        {
            firstName: "",
            lastName: "",
            email: "",
            password: "",
        }
    );
    const clearFields = () => {
        setFormInput({
            firstName: "",
            lastName: "",
            email: "",
            password: "",
        });
    }
    const handleSubmit = (event: { preventDefault: () => void; }) => {
        event.preventDefault();

        handleAddUser(formInput);
        clearFields();
        handleClose();
    }
    const handleInput = (event: { target: { name: any; value: any; }; }) => {
        const name = event.target.name;
        const newValue = event.target.value;
        setFormInput({[name]: newValue})
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Button variant="contained" onClick={handleOpen}>Add User</Button>
            </Grid>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            Add User
                        </Typography>
                        <form onSubmit={handleSubmit}>
                            <TextField
                                required
                                variant="standard"
                                id="firstName"
                                label="First Name"
                                name="firstName"
                                defaultValue={formInput.firstName}
                                onChange={handleInput}
                                sx={{
                                    m: 1, width: '100%'
                                }}
                            />
                            <TextField
                                required
                                variant="standard"
                                id="lastName"
                                label="Last Name"
                                name="lastName"
                                defaultValue={formInput.lastName}
                                onChange={handleInput}
                                sx={{
                                    m: 1, width: '100%'
                                }}
                            />
                            <TextField
                                required
                                variant="standard"
                                id="email"
                                label="Email"
                                name="email"
                                defaultValue={formInput.email}
                                onChange={handleInput}
                                sx={{
                                    m: 1, width: '100%'
                                }}
                            />
                            <TextField
                                required
                                variant="standard"
                                id="password"
                                label="Password"
                                name="password"
                                defaultValue={formInput.password}
                                onChange={handleInput}
                                sx={{
                                    m: 1, width: '100%'
                                }}
                            />
                            <Button type="submit" variant="contained">Submit</Button>
                        </form>
                    </Box>
                </Fade>
            </Modal>
        </Grid>
    );
}
