import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/system';

const userDashboardAccess = ['tucker@bluearrowmail.com', 'tcourtney@hanover.com', 'tunsworth@hanover.com',
    'dallas@bluearrowmail.com', 'nperez@hanover.com'];
let pages = ['Dashboard'];
const settings = ['Change Password', 'Logout'];

const HighlightedText = styled(Box)(({ theme }) => ({
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    borderRadius: '5px',
    padding: '0.25rem 0.5rem',
    color: 'white',
    fontWeight: 600,
}));

function ResponsiveAppBar({user, handlePageChange, totalMilesText}: any) {
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const [anchorElMetrics, setAnchorElMetrics] = React.useState<null | HTMLElement>(null);

    if (!user) {
        return (<div />)
    }
    if (userDashboardAccess.includes(user.email.toLowerCase()) && !pages.includes("Users")) {
        pages.push("Users");
    }
    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleOpenMetricsMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElMetrics(event.currentTarget);
    };

    const handleCloseMetricsMenu = () => {
        setAnchorElMetrics(null);
    };

    const handleNavigation = (event: any) => {
        handlePageChange(event, user.sub);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);

    };

    return (
        <AppBar position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <img style={{height: '10%', width: '10%'}} src={"https://bluearrow-public.s3.amazonaws.com/resources/images/BlueArrow_Logo_White.png"}  alt={"logo"}/>
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                    </Typography>

                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href=""
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {pages.map((page) => (
                            <Button
                                key={page}
                                onClick={handleNavigation}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                {page}
                            </Button>
                        ))}

                    </Box>
                    <Box sx={{ flexGrow: 0, display: 'flex', alignItems: 'center' }}>
                        <HighlightedText
                            sx={{
                                marginRight: 2,
                            }}
                        >
                            <Typography
                                variant="subtitle1"
                            >
                                Program Total Miles Protected: {new Intl.NumberFormat('en-US').format(Number(totalMilesText))}
                            </Typography>
                        </HighlightedText>
                        <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Avatar alt={user.given_name} src="/static/images/avatar/profile.jpg" />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {settings.map((setting) => (
                                <MenuItem key={setting} onClick={handleNavigation}>
                                    <Typography textAlign="center">{setting}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default ResponsiveAppBar;
