import React, {useEffect, useState} from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

interface LineChartComponentProps {
    data: (string | number)[][];
    title: string;
}

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const LineChartComponent: React.FC<LineChartComponentProps> = (props) => {
    const { data, title } = props;
    const [key, setKey] = useState(Math.random());

    useEffect(() => {
        const handleResize = () => {
            setKey(Math.random());  // change key to re-mount
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const labels = data.map((item) => item[0] as string);
    const values = data.map((item) => item[1] as number);

    return (
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <div style={{width: "50%"}}>
                <Line
                    key={key}
                    data={{
                        labels: labels,
                        datasets: [
                            {
                                label: title,
                                backgroundColor: 'rgba(0, 123, 255, 0.1)',
                                borderColor: 'rgba(0, 123, 255, 1)',
                                borderWidth: 1,
                                pointBackgroundColor: 'rgba(0, 123, 255, 1)',
                                data: values,
                            },
                        ],
                    }}
                    options={{
                        maintainAspectRatio: true,
                        responsive: true,
                        scales: {
                            x: { display: true, title: { text: 'Time' } },
                            y: { display: true, title: { text: 'Value' } },
                        },
                        plugins: {
                            tooltip: {
                                enabled: true,
                                position: 'nearest', // Tooltip will be displayed near the active point
                                intersect: false, // Tooltip is triggered when the mouse hovers close to a data point
                            },
                        },
                    }}
                />
            </div>
        </div>
    );
};

export default LineChartComponent;
