import * as React from 'react';
import { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export default function CustomCalendar({value, handleChange, labelText}: any) {

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                label={labelText}
                value={value}
                onChange={handleChange}
                renderInput={(params) => <TextField {...params} sx={{ minWidth: 100 }}/>}
            />
        </LocalizationProvider>
    );
}
