import {useContext, useEffect, useState} from 'react'
import CustomChart from "./CustomChart";
import {
    Autocomplete,
    FormControl, FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select, Switch,
    TextField
} from "@mui/material";
import * as React from 'react';
import {getAccounts, getAuthToken} from "./ApiCalls";
import { useAuth0 } from "@auth0/auth0-react"
import LoginButton from "./Auth0/LoginButton";
import UsersDashboard from "./UsersDashboard";
import ResponsiveAppBar from "./AppBar";
import ChangePasswordModal from "./ChangePasswordModal";
import CustomCalendar from "./CustomDatepicker";
import {AxiosBaseConfigContext} from "../App";
import axios from "axios";
import SearchIcon from '@mui/icons-material/Search';
import Box from "@mui/material/Box";
import Metrics from "./Metrics";
import CircularProgress from "@mui/material/CircularProgress";
import Rules from "./Rules";

require('process');


let chartText = 'Exceptions per 1000 miles';
const chartWidth = 1000;
const chartHeight = 400;
const chartArea = {width: '80%', height: '80%'};

enum Page {
    Login,
    Dashboard,
    Metrics,
    Rules,
    Users,
}

const createChart = (chartData: any, trendOptions: any, chartId: string) => {
    if (chartData && chartData.length > 0) {
        return <CustomChart trendOptions={trendOptions} trendData={chartData} chartId={chartId} />
    }
    return <div />;
}

function generateTicks(options: any) {
    let ticks = [];
    let loops: number;
    if (options.days.label != 'Custom') {
            loops = getRoundedColumns(options.days.value);
            for (let i = 0; i <= loops; i++) {
                if (options.days.label === '30') {
                    if (i % 5 === 0) {
                        ticks.push(i);
                    }
                }
                else {
                    ticks.push(i);
                }
            }

    }
    else {
        if (options.startDate && options.endDate) {
            let days = (options.endDate - options.startDate) / (1000 * 60 * 60 * 24);
            loops = getRoundedColumns(days);
            for (let i = 1; i <= loops; i++) {
                ticks.push(i);
            }
        }
    }

    return ticks;
}

function initializeOptions(title: string, timeFrame: string, ticks: Array<number>) {
    return {
        title: title,
        hAxis: {
            title: timeFrame,
            ticks: ticks,
            viewWindow: {
                minValue: 0
            },
            baseline: 0
        },
        vAxis: {
            title: chartText,
            viewWindow: {
                minValue: 0
            },
            baseline: 0
        },
        legend: 'none',
        trendlines: timeFrame !== "Days" ? { 0: {tooltip: false} } : '',
        chartArea: chartArea,
        width: chartWidth,
        height: chartHeight,
        titleTextStyle: {
            fontSize: 30
        },
    }
}

interface ChartType {
    num_speeding_exceptions: any[],
    num_seatbelt_exceptions: any[],
    num_harsh_cornering_exceptions: any[],
    num_harsh_acceleration_exceptions: any[],
    num_harsh_braking_exceptions: any[]
}

function getRoundedColumns(num: number) {
    return num < 31 ? num
                    : num < 92 ? Math.floor(num / 7)
                               : Math.floor(num / 30);
}

function processDates(data: any[], days: number) {
    // Sort the data array by datetime
    data.sort((a, b) => new Date(a.datetime).getTime() - new Date(b.datetime).getTime());

    let newData: any[] = [];

    for (let i = 0; i < data.length - 1; i++) {
        // Push current data to newData
        newData.push(data[i]);

        // Calculate the difference in days between the current data datetime and the next data datetime
        const currentDateTime = new Date(data[i].datetime);
        const nextDateTime = new Date(data[i + 1].datetime);
        const diffTime = Math.abs(nextDateTime.getTime() - currentDateTime.getTime());
        // Convert the time difference to hours instead of days, and check for at least 44 hours difference
        const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));

        // If the difference is more than 44 hours (1 day and 20 hours), fill in the missing days
        if (diffHours > 44) {
            const fullDaysDifference = Math.floor(diffHours / 24);
            for (let j = 1; j <= fullDaysDifference; j++) {
                const missingDate = new Date(currentDateTime.getTime() + j * 24 * 60 * 60 * 1000);
                // Set the time to 06:00:00, taking into account the DST if necessary
                missingDate.setHours(6, 0, 0, 0); // This may still need adjustment if you're not using UTC
                const missingDateString = formatDate(missingDate);
                // Create a new element with the required values set to zero
                newData.push({
                    db_name: "fill_data",
                    miles: "0",
                    datetime: missingDateString,
                    num_harsh_acceleration_exceptions: "0",
                    num_harsh_cornering_exceptions: "0",
                    num_harsh_braking_exceptions: "0",
                    num_seatbelt_exceptions: "0",
                    num_speeding_exceptions: "0"
                });
            }
        }
    }
    // Push the last element
    newData.push(data[data.length - 1]);

    // If new array length exceeds the daysFilter, remove the earliest elements
    while (newData.length > days) {
        newData.shift();
    }

    return newData;
}

// Function to format date to "YYYY-MM-DD HH:mm:ss"
function formatDate(date: Date) {
    const pad = (n: number) => n < 10 ? '0' + n : n;
    return date.getFullYear()+'-'
        + pad(date.getMonth()+1)+'-'
        + pad(date.getDate())+' '
        + pad(date.getHours())+':'
        + pad(date.getMinutes())+':'
        + pad(date.getSeconds());
}


function processExceptionData(responseData: any, daysFilter: number, timeFrame: string, setDeviceCount: any, customDates: any = null) {
    responseData.sort((a: any, b: any) => {
        a = new Date(a.datetime);
        b = new Date(b.datetime);
        return a - b;
    });

    const activeFrom = responseData[0].datetime;
    const activeTo = responseData[responseData.length - 1].datetime;
    // default deviceCount to empty string
    let deviceCount = '';
    // get deviceCount if it exists
    if ('device_count' in responseData[responseData.length - 1]) {
        deviceCount = responseData[responseData.length - 1].device_count;
    }
    responseData = responseData.filter((item: any) => {
        return !('miles' in item) || item.miles >= 1;
    });
    if (responseData.length > daysFilter && !customDates) {
        while (responseData.length > daysFilter) {
            responseData.shift();
        }
    }

    let filteredData;
    if (customDates) {
        filteredData = Object.values(responseData).filter((item: any) => {
            const itemDate = new Date(item.datetime);
            return itemDate >= customDates.startDate && itemDate <= customDates.endDate;
        });
        responseData = filteredData;
    }
    responseData = processDates(responseData, daysFilter);
    let tooltipText = "Exceptions per 1000 miles: ";
    timeFrame = getTimeFrame(daysFilter);
    let interval;
    if (timeFrame === 'Days') {
        interval = 1;
    }
    else if (timeFrame === 'Weeks') {
        interval = 7;
    }
    else {
        interval = 30;
    }

    let chartObject: { [key: string]: any } = {
        num_speeding_exceptions: [[timeFrame, chartText, {role: "tooltip", type: "string", p: {html: true }}]],
        num_seatbelt_exceptions: [[timeFrame, chartText, {role: "tooltip", type: "string", p: {html: true }}]],
        num_harsh_cornering_exceptions: [[timeFrame, chartText, {role: "tooltip", type: "string", p: {html: true }}]],
        num_harsh_acceleration_exceptions: [[timeFrame, chartText, {role: "tooltip", type: "string", p: {html: true }}]],
        num_harsh_braking_exceptions: [[timeFrame, chartText, {role: "tooltip", type: "string", p: {html: true }}]],
    };

    let numExceptionsByType: { [key: string]: number } = {
        num_speeding_exceptions: 0,
        num_seatbelt_exceptions: 0,
        num_harsh_cornering_exceptions: 0,
        num_harsh_acceleration_exceptions: 0,
        num_harsh_braking_exceptions: 0
    };

    const normalizeMilesBy = 1000;
    let columnCount = 0;
    let miles = 0;
    let loopCount = 0;
    for (let i = 0; i < responseData.length; i++) {
        Object.keys(numExceptionsByType).forEach((key: string) => {
            responseData[i][key] = Number(responseData[i][key]);
            numExceptionsByType[key] += responseData[i][key];
        });
        miles += parseFloat(responseData[i]['miles']);
        loopCount++;
        if (loopCount % interval === 0) {
            columnCount++;
            let exceptionsByType: { [key: string]: number } = {};
            Object.keys(numExceptionsByType).forEach((key) => {
                exceptionsByType[key] = numExceptionsByType[key] == 0 ? 0 : Math.round(numExceptionsByType[key])
                    / miles * normalizeMilesBy;
                chartObject[key].push([columnCount, exceptionsByType[key], tooltipText + exceptionsByType[key].toFixed(3)]);
            });

            numExceptionsByType = {
                num_speeding_exceptions: 0,
                num_seatbelt_exceptions: 0,
                num_harsh_cornering_exceptions: 0,
                num_harsh_acceleration_exceptions: 0,
                num_harsh_braking_exceptions: 0
            };
            miles = 0;
        }
    }

    //if not enough data to fill 1 bar in the chart then fill with an empty value for empty charts
    if (chartObject.num_harsh_cornering_exceptions.length === 1) {
        chartObject.num_speeding_exceptions.push([1, 0, tooltipText]);
        chartObject.num_seatbelt_exceptions.push([1, 0, tooltipText]);
        chartObject.num_harsh_cornering_exceptions.push([1, 0, tooltipText]);
        chartObject.num_harsh_acceleration_exceptions.push([1, 0, tooltipText]);
        chartObject.num_harsh_braking_exceptions.push([1, 0, tooltipText]);
    }
    else if (chartObject.num_harsh_cornering_exceptions.length === 2) {
        chartObject.num_speeding_exceptions[1] = [1, 0, tooltipText];
        chartObject.num_seatbelt_exceptions[1] = [1, 0, tooltipText];
        chartObject.num_harsh_cornering_exceptions[1] = [1, 0, tooltipText];
        chartObject.num_harsh_acceleration_exceptions[1] = [1, 0, tooltipText];
        chartObject.num_harsh_braking_exceptions[1] = [1, 0, tooltipText];
    }
    return {chartObject, deviceCount, activeFrom, activeTo};
}

function getTimeFrame(days: number) {
    return days < 31 ? 'Days'
           : days < 92 ? 'Weeks'
           : 'Months';
}


function getChartData(params: any): any {
    let account = params.selectedAccount;
    let url = '';
    if (account.credentials.database === 'all_accounts') {
        url = 'https://m4pzul24f3ckddfuqhypbow5wu0slppy.lambda-url.us-east-1.on.aws/';
    }
    else {
        url = 'https://v4dzu86w87.execute-api.us-east-1.amazonaws.com/dev//normalized-exceptions?db_name='+account.credentials.database;
    }
    let daysFilter = params.daysFilter
    let timeFrame = params.timeFrame;
    let customDates = params.customDates ?? null;
    let setDeviceCount = params.setDeviceCount;
    let setActiveFrom = params.setActiveFrom;
    let setActiveTo = params.setActiveTo;

    return axios.get(url).then((response) => {
        const processedData = processExceptionData(response.data, daysFilter.value, timeFrame, customDates);
        setDeviceCount(processedData.deviceCount); // set the state here
        setActiveFrom(processedData.activeFrom);
        setActiveTo(processedData.activeTo);
        return processedData.chartObject;
    }).catch((error) => {
        let chartObject: { [key: string]: any } = {
            num_speeding_exceptions: [[timeFrame, chartText, {role: "tooltip", type: "string", p: {html: true }}]],
            num_seatbelt_exceptions: [[timeFrame, chartText, {role: "tooltip", type: "string", p: {html: true }}]],
            num_harsh_cornering_exceptions: [[timeFrame, chartText, {role: "tooltip", type: "string", p: {html: true }}]],
            num_harsh_acceleration_exceptions: [[timeFrame, chartText, {role: "tooltip", type: "string", p: {html: true }}]],
            num_harsh_braking_exceptions: [[timeFrame, chartText, {role: "tooltip", type: "string", p: {html: true }}]],
        };
        setDeviceCount('');
        setActiveFrom('');
        setActiveTo('');
        chartObject.num_speeding_exceptions.push([1, 0, '']);
        chartObject.num_seatbelt_exceptions.push([1, 0, '']);
        chartObject.num_harsh_cornering_exceptions.push([1, 0, '']);
        chartObject.num_harsh_acceleration_exceptions.push([1, 0, '']);
        chartObject.num_harsh_braking_exceptions.push([1, 0, '']);
        //todo: maybe email error later
        return chartObject;
    })
}

function capitalizeWords(str: string) {
    return str.toLowerCase().replace(/\b[a-z]/g, function(letter) {
        return letter.toUpperCase();
    });
}

export default function TrendChart() {
    const [deviceCount, setDeviceCount] = React.useState('');
    const [activeFrom, setActiveFrom] = React.useState('');
    const [activeTo, setActiveTo] = React.useState('');
    const [showActiveAccounts, setShowActiveAccounts] = React.useState(true);
    const { axiosBaseConfig, setAxiosBaseConfig } = useContext(AxiosBaseConfigContext);
    const [totalMiles, setTotalMiles] = useState(0);
    const [daysFilter, setDaysFilter] = useState({label: '30', value: 30});
    const { logout, user, isAuthenticated, isLoading } = useAuth0();
    const [userId, setUserId] = useState('');
    const [currentUserId, setCurrentUserId] = useState('');
    const [showChangePasswordModal, setShowChangePasswordModal] = React.useState(false);
    const [calendarOpen, setCalendarOpen] = useState(false);
    const [page, setPage] = useState(Page.Dashboard);
    const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate()-30)));
    const [endDate, setEndDate] = useState(new Date());
    const [accountsSelectWidth, setAccountsSelectWidth] = useState(400);
    const [ticks, setTicks] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30]);
    const [isLoadingCharts, setIsLoadingCharts] = useState(false);
    const [charts, setCharts] = useState([(<div />)])
    const [activeAccounts, setActiveAccounts] = useState([{common_name: '', database: '',
        username: '',
        encrypted_password: '',
    }]);
    const [inactiveAccounts, setInactiveAccounts] = useState([{common_name: '', database: '',
        username: '',
        encrypted_password: '',
    }]);
    const [chartData, setChartData] = useState({
        num_speeding_exceptions: [],
        num_seatbelt_exceptions: [],
        num_harsh_cornering_exceptions: [],
        num_harsh_acceleration_exceptions: [],
        num_harsh_braking_exceptions: []
    });
    const [selectedAccount, setSelectedAccount] = useState({
        credentials: {
            database: '',
            userName: '',
            password: ''
        }, common_name: ''});

    const timeFrame = getTimeFrame(daysFilter.value);
    const handleOpenChangePasswordModal = (event: any, userId: string = '') => {
        userId === '' ? setCurrentUserId(event.currentTarget.dataset.userId) : setCurrentUserId(userId);
        setShowChangePasswordModal(true);
    }
    useEffect(() => {
        getAuthToken().then((response: any) => {
            setAxiosBaseConfig({
                headers: {"Authorization": "Bearer " + response},
                baseURL: 'https://dev-57qkqgjpw47qb3zq.us.auth0.com/api/v2'
            });
        })
    }, []);
    if (!axiosBaseConfig) {
        return (<div />);
    }
    if (user && userId === '' && user.sub) {
        setUserId(user.sub);
        setCurrentUserId(user.sub);
    }
    const handleDaysChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        if (event.target.value === "Custom") {
            setDaysFilter(prevState => {
                return { ...prevState, label: 'Custom' };
            });
        } else {
            setDaysFilter({label: String(event.target.value), value: Number(event.target.value)});
        }
    }


    let activeAccountNames = activeAccounts.map(account => {return account.common_name;});
    let inactiveAccountNames = inactiveAccounts.map(account => {return account.common_name;});

    useEffect(() => {
        let dayOptions = {days: daysFilter, startDate: startDate, endDate: endDate};
        let newTicks = generateTicks(dayOptions);
        setTicks(newTicks);
    }, [daysFilter.value]);

    //get accounts
    useEffect(() => {
        let accountsFromDB = getAccounts();

        let activeAccounts = accountsFromDB.filter((account:any) => account.Active === true);
        let inactiveAccounts = accountsFromDB.filter((account:any) => account.Active !== true);
        activeAccounts.push({
            database: 'all_accounts',
            username: '',
            encrypted_password: '',
            common_name: 'All Accounts'
        });

        (async () => {
            await fetchTotalMiles();
        })();
        let sortedData = activeAccounts.sort((a: any, b: any) => a.common_name.toUpperCase() < b.common_name.toUpperCase() ? -1 : 1);
        setActiveAccounts(sortedData);
        sortedData = inactiveAccounts.sort((a: any, b: any) => a.common_name.toUpperCase() < b.common_name.toUpperCase() ? -1 : 1);
        setInactiveAccounts(sortedData);
        let selectedAccountValue = {
            credentials: { //change to accounts[0] for values
                database: activeAccounts[0].database,
                userName: activeAccounts[0].username,
                password: activeAccounts[0].encrypted_password,
            },
            common_name: activeAccounts[0].common_name,
        }
        setSelectedAccount(selectedAccountValue);
    }, []);

    const fetchTotalMiles = async () => {
        try {
            const response = await axios.get('https://v4dzu86w87.execute-api.us-east-1.amazonaws.com/dev//total-miles');
            const responseData = JSON.parse(response.data.body);
            if (responseData.totalMiles > 0) {
                setTotalMiles(responseData.totalMiles);
            } else {
                setTotalMiles(0);
            }
        } catch (error) {
            console.error('Error fetching total miles:', error);
            setTotalMiles(0);
        }
    };


    useEffect(() => {
        if (selectedAccount.credentials.database != '') {
            switch(daysFilter.label) {
                case 'Custom':
                    if (!calendarOpen){
                        handleOpenCalendar();
                    }
                    break;
                default:
                    setIsLoadingCharts(true);
                    if (calendarOpen) {
                        setCalendarOpen(false);
                    }
                    if (selectedAccount.credentials.database != '') {
                        let params = {selectedAccount: selectedAccount,
                            daysFilter: daysFilter,
                            timeFrame: timeFrame,
                            setDeviceCount: setDeviceCount,
                            setActiveFrom: setActiveFrom,
                            setActiveTo: setActiveTo };
                        getChartData(params)
                            .then((response: React.SetStateAction<{ num_speeding_exceptions: never[]; num_seatbelt_exceptions: never[]; num_harsh_cornering_exceptions: never[]; num_harsh_acceleration_exceptions: never[]; num_harsh_braking_exceptions: never[]; }>) => {
                            setChartData(response);
                            setIsLoadingCharts(false);
                        });
                    }
                    break;
            }
        }
    }, [selectedAccount, daysFilter]);

    const handleOpenCalendar = () => {
        setCalendarOpen(true);
    }


    const handlePageChange = (event: any, userId: string = '') => {
        let str = capitalizeWords(event.target.innerText);
        switch (str) {
            case 'Dashboard':
                setPage(Page.Dashboard);
                break;
            case 'Users':
                setPage(Page.Users);
                break;
            case 'Metrics':
                setPage(Page.Metrics);
                break;
            case 'Rules':
                setPage(Page.Rules);
                break;
            case 'Logout':
                logout({ returnTo: window.location.origin })
                break;
            case 'Change Password':
                handleOpenChangePasswordModal(true, userId)
                setShowChangePasswordModal(true);
                break;
            default:
                setPage(Page.Login);
                break;
        }
    }



    const handleSearch = (event: any) => {
        if (startDate && endDate) {
            setIsLoadingCharts(true);
            const numMilliseconds = Math.round(endDate.getTime() - startDate.getTime());
            const numDays = Math.round(numMilliseconds / (1000 * 60 * 60 * 24));
            setDaysFilter({label: 'Custom', value: numDays});
            let params = {
                selectedAccount: selectedAccount,
                daysFilter: {label: 'Custom', value: numDays},
                timeFrame: getTimeFrame(numDays),
                setDeviceCount: setDeviceCount,
                setActiveFrom: setActiveFrom,
                setActiveTo: setActiveTo,
                customDates: {startDate: startDate, endDate: endDate}
            };
            getChartData(params).then((response: React.SetStateAction<{ num_speeding_exceptions: never[]; num_seatbelt_exceptions: never[]; num_harsh_cornering_exceptions: never[]; num_harsh_acceleration_exceptions: never[]; num_harsh_braking_exceptions: never[]; }>) => {
                setChartData(response);
                setIsLoadingCharts(false);
            });
        }
    }
    const handleActiveAccountsToggleChange = (event: any) => {
        // Toggle the showActiveAccounts state
        setShowActiveAccounts(!showActiveAccounts);

        // Get the first account from the appropriate list
        let firstAccount = !showActiveAccounts ? activeAccounts[0] : inactiveAccounts[0];

        // Update the selected account
        if (firstAccount) {
            setSelectedAccount({
                credentials: {
                    database: firstAccount.database,
                    userName: firstAccount.username,
                    password: firstAccount.encrypted_password,
                },
                common_name: firstAccount.common_name,
            });
        }
    }
    const handleStartDateChange = (event: any) => {
        setStartDate(event.$d);
    }

    const handleEndDateChange = (event: any) => {
        setEndDate(event.$d);
    }





    useEffect(() => {
        if (!isLoadingCharts) {
            let UnbuckledOptions;
            let SpeedingOptions;
            let HarshCorneringOptions;
            let HarshBrakingOptions;
            let HarshAccelerationOptions;

            if (chartData && chartData.num_seatbelt_exceptions) {
                let tf = chartData.num_seatbelt_exceptions[0] ? chartData.num_seatbelt_exceptions[0][0] : timeFrame;
                UnbuckledOptions = initializeOptions("Seatbelt Unbuckled", tf, ticks);
            }
            if (chartData && chartData.num_speeding_exceptions) {
                let tf = chartData.num_seatbelt_exceptions[0] ? chartData.num_seatbelt_exceptions[0][0] : timeFrame;
                SpeedingOptions = initializeOptions("Speeding", tf, ticks);
            }
            if (chartData && chartData.num_harsh_cornering_exceptions) {
                let tf = chartData.num_seatbelt_exceptions[0] ? chartData.num_seatbelt_exceptions[0][0] : timeFrame;
                HarshCorneringOptions = initializeOptions("Harsh Cornering", tf, ticks);
            }
            if (chartData && chartData.num_harsh_acceleration_exceptions) {
                let tf = chartData.num_seatbelt_exceptions[0] ? chartData.num_seatbelt_exceptions[0][0] : timeFrame;
                HarshBrakingOptions = initializeOptions("Harsh Braking", tf, ticks);
            }
            if (chartData && chartData.num_harsh_braking_exceptions) {
                let tf = chartData.num_seatbelt_exceptions[0] ? chartData.num_seatbelt_exceptions[0][0] : timeFrame;
                HarshAccelerationOptions = initializeOptions("Harsh Acceleration", tf, ticks);
            }

            const UnbuckledChart = createChart(chartData.num_seatbelt_exceptions, UnbuckledOptions, 'data-seatbelt-unbuckled');
            const SpeedingChart = createChart(chartData.num_speeding_exceptions, SpeedingOptions, 'data-speeding');
            const HarshCorneringChart = createChart(chartData.num_harsh_cornering_exceptions, HarshCorneringOptions, 'data-harsh-cornering');
            const HarshBrakingChart = createChart(chartData.num_harsh_braking_exceptions, HarshBrakingOptions, 'data-harsh-braking');
            const HarshAccelerationChart = createChart(chartData.num_harsh_acceleration_exceptions, HarshAccelerationOptions, 'data-harsh-acceleration');
            setCharts([SpeedingChart, HarshBrakingChart, HarshAccelerationChart, HarshCorneringChart, UnbuckledChart]);
        }
    }, [isLoadingCharts])

    const handleAccountChange = (event: React.SyntheticEvent, value: string | null): void => {
        // Determine the appropriate account list based on showActiveAccounts
        let accountList = showActiveAccounts ? activeAccounts : inactiveAccounts;

        let currentAccount = accountList.find(element => element.common_name === value);
        if (!currentAccount) {
            return;
        }
        let selectedAccountValue = {
            credentials: {
                database: currentAccount.database, //currentAccount.database
                userName: currentAccount.username, //currentAccount.username
                password: currentAccount.encrypted_password, //currentAccount.encrypted_password
            },
            common_name: currentAccount.common_name, //currentAccount.common_name
        };
        setSelectedAccount(selectedAccountValue);
    }


    if (!isAuthenticated) {
        if (isLoading) {
            return <div />;
        }
        return(<LoginButton />);
    }
    const endDateDisplay = showActiveAccounts
        ? new Date().toLocaleDateString('default', { month: 'long', year: 'numeric' })
        : new Date(activeTo).toLocaleDateString('default', { month: 'long', year: 'numeric' });


    return (
        <>
            {showChangePasswordModal && <ChangePasswordModal close={() => setShowChangePasswordModal(false)}
                                                             userId={currentUserId} />}
            <ResponsiveAppBar user={user} handlePageChange={handlePageChange} totalMilesText={totalMiles} />
            {
                page === Page.Dashboard ?
                    <div>
                        <Grid container>
                            <Grid item lg={3} xl={2}>
                                <Paper variant="outlined" sx={{p: 2, mt: 2, mb:6, ml: 2, mr: 2}}>
                                    <h4>Account Information</h4>
                                    <p>Device Count: {deviceCount}</p>
                                    <p>
                                        Active:
                                        {activeFrom ?
                                            `${new Date(activeFrom).toLocaleDateString('default', { month: 'long', year: 'numeric' })} - ${endDateDisplay}` : ''}
                                    </p>
                                </Paper>
                            </Grid>
                            <Grid item lg={7} xl={8}>
                                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <h2>Hanover BlueArrow Geotab Dashboard</h2>
                                    <Grid container spacing={2} justifyContent="center">
                                        <Grid item>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={showActiveAccounts}
                                                        onChange={handleActiveAccountsToggleChange}
                                                        name="Active/Inactive"
                                                        color="primary"
                                                    />
                                                }
                                                label={showActiveAccounts ? "Active Accounts" : "Inactive Accounts"}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <FormControl>
                                                <Autocomplete
                                                    disablePortal
                                                    disableClearable
                                                    sx={{ width: accountsSelectWidth }}
                                                    value={selectedAccount.common_name}
                                                    id={'select-accounts'}
                                                    onChange={handleAccountChange}
                                                    options={showActiveAccounts ? activeAccountNames : inactiveAccountNames}
                                                    renderInput={(params) => <TextField {...params} label={'Accounts'} />}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <FormControl>
                                                <InputLabel id="select-days">Days</InputLabel>
                                                <Select
                                                    autoWidth={true}
                                                    labelId="select-days"
                                                    id="select-days"
                                                    value={daysFilter.label}
                                                    onChange={handleDaysChange}
                                                    label="Days"
                                                >
                                                    <MenuItem value={'30'}>Last 30 days</MenuItem>
                                                    <MenuItem value={'90'}>Last 90 days</MenuItem>
                                                    <MenuItem value={'360'}>Last 360 days</MenuItem>
                                                    <MenuItem value={'Custom'}>Custom</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            {calendarOpen ?
                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                                    <CustomCalendar value={startDate} handleChange={handleStartDateChange} labelText="Start date"/>
                                                    <CustomCalendar value={endDate} handleChange={handleEndDateChange} labelText="End date"/>
                                                    <IconButton id={'search'} sx={{mt: 1}} size={'large'} onClick={handleSearch} >
                                                        <SearchIcon />
                                                    </IconButton>
                                                </Box>
                                                : null
                                            }
                                        </Grid>


                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item xs={2}/>
                        </Grid>

                        {
                            isLoadingCharts ? (
                                <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '20vh', marginTop: 20 }}>
                                    <CircularProgress />
                                </Grid>
                            ) : (
                                <Grid container spacing={2}>
                                    {charts.map((chart, index) => {
                                        return (
                                            <Grid item xs={6} key={index}>{chart}</Grid>
                                        );
                                    })}
                                    <Grid item xs={3}/>
                                </Grid>
                            )
                        }
                    </div>
                    : page === Page.Users ?
                    <>
                        <h2>Hanover BlueArrow Geotab Users</h2>
                        <UsersDashboard handleOpenChangePasswordModal={handleOpenChangePasswordModal} />
                    </>
                    : page === Page.Metrics ?
                    <>
                        <Metrics />
                    </>
                    : page === Page.Rules ?
                    <>
                        <Rules />
                    </>
                        : <></>
            }
        </>
    )



}
