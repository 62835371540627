import './App.css';
import TrendChart from './scripts/TrendChart';
import React, { createContext, useState, useContext } from 'react';

// Create a context to store the dynamic variable
export const AxiosBaseConfigContext = createContext<{
    axiosBaseConfig: {headers: {"Authorization": string}, baseURL: string}
    setAxiosBaseConfig: React.Dispatch<React.SetStateAction<{ headers: { Authorization: string }, baseURL: string }>>;
}>({
    axiosBaseConfig: {headers: {"Authorization": ''}, baseURL: ''},
    setAxiosBaseConfig: () => {},
});

// Create a provider to make the dynamic variable available to all components
// Specify the type of the children prop
function AxiosBaseConfigProvider({ children }: { children: React.ReactNode }) {
    // Set the initial value of the dynamic variable
    const [axiosBaseConfig, setAxiosBaseConfig] = useState<{
        headers: { Authorization: string },
        baseURL: string,
    }>({ headers: { Authorization: '' }, baseURL: '' });

    // Return the provider, passing the dynamic variable and its setter to the context value
    return (
        <AxiosBaseConfigContext.Provider value={{ axiosBaseConfig, setAxiosBaseConfig }}>
            {children}
        </AxiosBaseConfigContext.Provider>
    );
}

function App() {
  return (
    <div className="App">
        <AxiosBaseConfigProvider>
            <TrendChart />
        </AxiosBaseConfigProvider>
    </div>
  );
}

export default App;
