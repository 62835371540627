import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const rules = [
    {
        title: 'Speeding',
        description: 'The speeding rule for the Hanover Telematics program is triggered once a driver has driven 6 MPH over the posted speed limit for more than 20 seconds. We put a 20 second buffer on the rule being triggered to ensure it is actually an event of a driver speeding and not the consequence of a safe passing or cruise control.',
        suggestions: 'Suggestions for Speeding',
    },
    {
        title: 'Seat Belt',
        description: 'Description for Seat Belt',
        suggestions: 'Suggestions for Seat Belt',
    },
    {
        title: 'Harsh Cornering',
        description: 'Description for Harsh Cornering',
        suggestions: 'Suggestions for Harsh Cornering',
    },
    {
        title: 'Harsh Acceleration',
        description: 'Description for Harsh Acceleration',
        suggestions: 'Suggestions for Harsh Acceleration',
    },
    {
        title: 'Harsh Braking',
        description: 'Description for Harsh Braking',
        suggestions: 'Suggestions for Harsh Braking',
    },
];

function Rules() {
    return (
        <div>
            {rules.map((rule, index) => (
                <Accordion key={index}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6">{rule.title}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <strong>Description:</strong> {rule.description}
                            <br />
                            <strong>Suggestions:</strong> {rule.suggestions}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            ))}
        </div>
    );
}

export default Rules;
