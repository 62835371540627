import axios, { AxiosRequestConfig } from "axios";
import {useContext} from "react";
import {AxiosBaseConfigContext} from "../App";


export default function getAllExceptions(api: any, daysFilter: string) {
    let seatbeltUnbuckledExceptions = getExceptions(api, "Speeding", daysFilter);
    let speedingExceptions: never[] = []//getExceptions("Speeding", daysFilter);
    let harshBrakingExceptions: never[] = []//getExceptions("Harsh Braking", daysFilter);
    let harshAccelerationExceptions: never[] = []//getExceptions("Harsh Acceleration", daysFilter);
    let harshCorneringExceptions: never[] = []//getExceptions("Harsh Cornering", daysFilter);
    return {
        seatbeltUnbuckled: seatbeltUnbuckledExceptions,
        speeding: speedingExceptions,
        harshBraking: harshBrakingExceptions,
        harshAcceleration: harshAccelerationExceptions,
        harshCornering: harshCorneringExceptions,
    };
}

export function getAccounts() {
    let url = 'https://v4dzu86w87.execute-api.us-east-1.amazonaws.com/dev/account';
    let xmlHttpReq = new XMLHttpRequest();
    xmlHttpReq.open("GET", url, false);
    xmlHttpReq.send(null);
    return JSON.parse(xmlHttpReq.responseText);
}

function getExceptions(api: any, ruleName: string, daysFilter: string) {
    let daysSplit: number;
    //split dates into weeks or months for each column
    daysSplit = daysFilter === '360' ? 30 : 7;

    return api.call("Get", {
        "typeName": "Rule",
        "search": {"name": ruleName}
    }, function (result: { id: any; }[]) {
        let timeFrame = daysFilter;
        let ruleId = result[0].id;
        let toDate = new Date();
        let fromDate = new Date();
        fromDate.setDate(fromDate.getDate() - Number(daysFilter));
        api.call("Get", {
            "typeName": "ExceptionEvent",
            "search": {
                "ruleSearch": {
                    "id": ruleId
                },
                "fromDate": fromDate,
                "toDate": toDate
            }
        }, function (result: any) {
            let exceptionEvents = result;
            let eventDates: any[][] = [];
            result.forEach((element: any) => {
                element.activeFrom = new Date(element.activeFrom);
            });
            let sortedResult = result.sort(
                (a: any, b: any) => a.activeFrom - b.activeFrom
            );
            let dayComparisonArray: Date[] = [];
            //loop and fill dayComparisonArray with dates split into the amount
            //that matches the number of columns to display
            switch (daysFilter) {
                case '360':
                    for (let i = 0; i < 12; i++) {
                        let day = new Date();
                        day.setDate(day.getDate() - (daysSplit * (i)));
                        dayComparisonArray.push(day);
                    }
                    break;
                case '90':
                    for (let i = 0; i < 13; i++) {
                        let day = new Date();
                        day.setDate(day.getDate() - (daysSplit * (i)));
                        dayComparisonArray.push(day);
                    }
                    break;
                case '30':
                    for (let i = 0; i < 4; i++) {
                        let day = new Date();
                        day.setDate(day.getDate() - (daysSplit * (i)));
                        dayComparisonArray.push(day);
                    }
                    break;
                case 'Custom':
            }
            //loop through results and then loop through dayComparisonArray
            //to fill eventDates array with exception events split for each column
            //that needs to be displayed
            dayComparisonArray.reverse();
            result.forEach((element: any) => {
                dayComparisonArray.some((dayComparison, key) => {
                    if (element.activeFrom < dayComparison) {
                        if (eventDates[key]) {
                            eventDates[key].push(element);
                        } else {
                            eventDates[key] = [element];
                        }
                        return true;
                    }
                })
            });


            //multicall to get odometer value pairs for each
            dayComparisonArray.forEach(element => {
                let fromDateTest = new Date(element);
                fromDateTest.setDate(fromDateTest.getDate() - 1);
            });
            let odometerPairs: any[] = [];
            let odometerCalls: any[] = [];
            let odometerData: any = {};
            return api.call("Get", {"typeName": "Device"},
                function (results: any) {
                    let deviceIds = results.map((element: { id: any; }) => {
                        return element.id
                    });
                    let totalColumns = daysFilter === '360' ? 12
                        : daysFilter === '90' ? 13
                            : 4;
                    let totalLoops = totalColumns + 1;
                    let endReturn: any;
                    let returnObject: any[] = [];
                    for (let i = 0; i < totalLoops; i++) {
                        let odometerFromDate = new Date();
                        odometerFromDate.setDate(odometerFromDate.getDate() - ((totalLoops - i) * daysSplit));
                        odometerCalls = [];
                        deviceIds.forEach((id: string) => {
                            odometerCalls.push(["Get", {
                                "typeName": "StatusData",
                                "search": {
                                    "deviceSearch": {
                                        "id": id
                                    },
                                    "diagnosticSearch": {
                                        "id": "DiagnosticOdometerId"
                                    },
                                    "fromDate": odometerFromDate,
                                    "toDate": odometerFromDate
                                }
                            }])
                        });
                        api.multiCall(odometerCalls, function (multicallData: any) {
                            multicallData.forEach((odometerValue: any) => {
                                if (!odometerValue[0]) {
                                    return;
                                }
                                if (!odometerData[odometerValue[0].device.id]) {
                                    odometerData[odometerValue[0].device.id] = {};
                                }
                                odometerData[odometerValue[0].device.id][i] = {
                                    odometer: Math.trunc(odometerValue[0].data / 1609.344),
                                    datetime: odometerValue[0].dateTime
                                };
                            });
                            let dataLength = Object.keys(odometerData[
                                Object.keys(odometerData)[
                                Object.keys(odometerData).length - 1]]).length
                            if (dataLength === totalLoops) {
                                returnObject.push(odometerData);
                            }
                        });
                    }
                    return returnObject;
                }
            );
        }, function (e: any) {
            console.log(e);
        });


    }, function (e: any) {
        console.error("Failed2:", e);
    });
    //console.log("Done: ", result);
}


const postHeaders = {'Content-Type': 'application/json'}

/**
 * Make an api request to the Auth0 API to get all users
 * @return [] an array of objects, each with the properties name, email, and user_id
 */
export async function getUsers(axiosBaseConfig: any) {
    try {
        let results = await axios.get('/users?fields=given_name,family_name,email,user_id', axiosBaseConfig);
        return results.data;
    }
    catch (error: any) {
        console.error(error);
    }
}

/**
 * Make an api request to the Auth0 API to create a user
 * @param params This needs to be an array or object with email, fname, lname, and password
 * @param axiosBaseConfig
 * @return string returns the user_id from the newly created user
 */
export async function createUser(params:any, axiosBaseConfig: any) {
    let config = axiosBaseConfig;
    config.headers = Object.assign(config.headers, postHeaders);
    let data = {
        email: params['email'],
        given_name: params.firstName,
        family_name: params.lastName,
        name: params['firstName'] + ' ' + params['lastName'],
        connection: 'Username-Password-Authentication',
        password: params['password'],
        verify_email: false
    };

    try {
        let results = await axios.post('/users', data, config);
        return results.data.user_id;
    }
    catch (error) {
        console.error(error);
    }
}


/**
 * Make an api request to the Auth0 API to delete a user
 * @param id this is the user_id that comes back from a user creation or the getUsers() function when the page loads
 * @param axiosBaseConfig
 */
export function deleteUser(id: string, axiosBaseConfig: any) {
    let url = '/users/' + id;
    axios.delete(url, axiosBaseConfig)
        .then(function(response) {
        }).catch(function(error) {
            console.log(error);
    });
}


/**
 * Make an api request to the Auto0 API to change a users's password
 * @param password The new password
 * @param id the user_id of the user that will have their password changed
 * @param axiosBaseConfig
 */
export function updatePassword(password: string, id: string, axiosBaseConfig: any) {
    let config = axiosBaseConfig;
    config.headers = Object.assign(config.headers, postHeaders);
    let data = {
        password: password
    };

    axios.patch('/users/' + id, data, config).then(function(response) {
    }).catch(function(error) {
        console.error(error);
    });
}


export async function getAuthToken() {
    let body = {
        client_id: "CldgxV0jtIw4q510nAo7LcPHM8vbSN1g",
        client_secret: "w8W2TgR3z3Kk2L3m6taKzTvRUKQFu4RhBPmyOy3P7slGxzRBLs6ujGd8s3_IVTq9",
        audience: "https://dev-57qkqgjpw47qb3zq.us.auth0.com/api/v2/",
        grant_type: "client_credentials",
    };
    let headers = {
        'Content-Type': "application/json"
    };

    // Return the promise from the axios.post method
    return axios.post('https://dev-57qkqgjpw47qb3zq.us.auth0.com/oauth/token', body, {headers: headers})
        .then(function(response) {
            return response.data.access_token;
        }).catch(function(error) {
            console.log(error);
            return 0;
    });
}
