import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import {Button, Grid, IconButton, TextField} from "@mui/material";
import AddUserModal from "./AddUserModal";
import {useContext, useEffect, useRef} from "react";
import {createUser, deleteUser, getUsers, updatePassword} from "./ApiCalls";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import ChangePasswordModal from "./ChangePasswordModal";
import {AxiosBaseConfigContext} from "../App";

interface Column {
    id: 'firstName' | 'lastName' | 'email' | 'buttons';
    label: string;
    minWidth?: number;
    align?: 'right';
    format?: (value: number) => string;
}

const columns: readonly Column[] = [
    { id: 'firstName', label: 'First Name', minWidth: 170 },
    { id: 'lastName', label: 'Last Name', minWidth: 170 },
    { id: 'email', label: 'Email', minWidth: 200 },
    { id: 'buttons', label: '', minWidth: 20 },
];

interface Data {
    firstName: string;
    lastName: string;
    email: string;
    buttons: JSX.Element;
}

function createData(
    firstName: string,
    lastName: string,
    email: string,
    buttons: JSX.Element
): Data {
    return { firstName, lastName, email, buttons};
}



export default function UsersDashboard({handleOpenChangePasswordModal}: any) {
    const { axiosBaseConfig, setAxiosBaseConfig } = useContext(AxiosBaseConfigContext);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleDeleteUser = (event: any) => {
        deleteUser(event.currentTarget.dataset.userId, axiosBaseConfig);
        let email = event.currentTarget.id.split('delete-').pop();
        const newArr = rowsRef.current.filter(object => {
            return object.email !== email;
        });
        setRows(newArr);
    }

    const handlePasswordData = (event: any) => {
        handleOpenChangePasswordModal(event, event.currentTarget.dataset.userId);
    }

    const handleEditUser = (event: any) => {
        updatePassword(event.currentTarget.newPassword, event.currentTarget.dataset.userId, axiosBaseConfig);
        let email = event.currentTarget.id.split('edit-').pop();
        const newArr = rowsRef.current.filter(object => {
            return object.email !== email;
        });
        setRows(newArr);
    }


    const [loading, setLoading] = React.useState(true);
    const [rows, setRows] = React.useState([
        createData('t', '', 'testuser@test.com',
            <div><Button id="delete-default" variant="contained" onClick={handleDeleteUser}>Delete</Button>
                <Button id="edit-default" variant="contained" onClick={handleDeleteUser}>Delete</Button></div>
        )
    ]);

    useEffect(() => {
        getUsers(axiosBaseConfig).then(function(results) {
            let newRows: [Data] = [createData('', '', '', <div />)];
            results.forEach((data: any) => {
                if (newRows[0].firstName === '') {
                    newRows = [createData(data.given_name ?? "Blake",
                        data.family_name ?? "Peebles",
                        data.email,
                        <div><IconButton id={'edit-'+data.email} data-user-id={data.user_id} onClick={handlePasswordData}><EditIcon data-user-id={data.user_id} /></IconButton>
                            <IconButton id={'delete-'+data.email} data-user-id={data.user_id} onClick={handleDeleteUser}><DeleteForeverIcon data-user-id={data.user_id} /></IconButton></div>)]
                }
                else {
                    newRows.push(createData(data.given_name ?? "Blake",
                        data.family_name ?? "Peebles", data.email,
                        <div><IconButton id={'edit-'+data.email} data-user-id={data.user_id} onClick={handlePasswordData}><EditIcon data-user-id={data.user_id} /></IconButton>
                            <IconButton id={'delete-'+data.email} data-user-id={data.user_id} onClick={handleDeleteUser}><DeleteForeverIcon data-user-id={data.user_id} /></IconButton></div>));
                }
            })
            setRows(newRows);
            setLoading(false);
        });
    }, [])

    //for some reason i needed to create a reference to the rows state because the initial state was being used
    //inside of handleDeleteUser since I was binding handleDeleteUser to the initial state and trying to use the
    //rows state inside to delete a single row but by doing so it caused the initial row state to be used instead
    //of the current state.
    const rowsRef = useRef([createData('', '', '', <Button />)]);
    rowsRef.current = rows;

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleAddUser = (data: any) => {
        createUser(data, axiosBaseConfig).then((results) => {
            let newRow = createData(data.firstName,
                data.lastName,
                data.email,
                <div><IconButton id={'edit-'+data.email} data-user-id={data.user_id} onClick={handlePasswordData}><EditIcon data-user-id={data.user_id} /></IconButton>
                <IconButton id={'delete-'+data.email} data-user-id={results} onClick={handleDeleteUser} ><DeleteForeverIcon data-user-id={data.user_id} /></IconButton></div>)
            setRows([...rows, newRow]);
        });
    }

    if (loading) {
        return (<div>Loading...</div>);
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={2} />
            <Grid item xs={8}>
                <Paper sx={{width: '100%', overflow: 'hidden'}}>
                    <TableContainer>
                        <div style={{marginLeft: 1000}}><AddUserModal handleAddUser={handleAddUser}/></div>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{minWidth: column.minWidth}}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.email}>
                                                {columns.map((column) => {
                                                    const value = row[column.id];
                                                    return (
                                                        <TableCell key={column.id} align={column.align}>
                                                            {value}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </TableContainer>
                </Paper>
            </Grid>
        </Grid>
    );
}
