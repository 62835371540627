import * as React from 'react';
import {useContext, useReducer} from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {Grid, TextField} from "@mui/material";
import {updatePassword} from "./ApiCalls";
import {AxiosBaseConfigContext} from "../App";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


// @ts-ignore
export default function ChangePasswordModal({userId, close}) {
    const { axiosBaseConfig, setAxiosBaseConfig } = useContext(AxiosBaseConfigContext);
    const [formInput, setFormInput] = useReducer(
        (state: any, newState: any) => ({ ...state, ...newState }),
        {
            password: "",
        }
    );
    const clearFields = () => {
        setFormInput({
            password: "",
        });
    }
    const handleSubmit = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        updatePassword(formInput.newPassword, userId, axiosBaseConfig);
        close();
        clearFields();
    }
    const handleInput = (event: { target: { name: any; value: any; }; }) => {
        const name = event.target.name;
        const newValue = event.target.value;
        setFormInput({[name]: newValue})
    }

    return (
        <Grid container spacing={2}>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={true}
                onClose={close}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={true}>
                    <Box sx={style}>
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            Change Password
                        </Typography>
                        <form onSubmit={handleSubmit}>
                            <TextField
                                required
                                variant="standard"
                                id="newPassword"
                                label="New Password"
                                name="newPassword"
                                onChange={handleInput}
                                sx={{
                                    m: 1, width: '100%'
                                }}
                            />
                            <Button type="submit" variant="contained">Submit</Button>
                        </form>
                    </Box>
                </Fade>
            </Modal>
        </Grid>
    );
}
