import Chart, { GoogleChartTicks } from 'react-google-charts'

function CustomChart(props: { trendData: {} | any[] | undefined; trendOptions: Partial<{ [otherOptionKey: string]: any; width: number; height: number; is3D: boolean; title: string; backgroundColor: string; hAxis?: { [otherOptionKey: string]: any; minValue?: any; maxValue?: any; ticks?: GoogleChartTicks | undefined; title?: string | undefined; viewWindow?: { max?: any; min?: any } | undefined } | undefined; vAxis?: { [otherOptionKey: string]: any; minValue?: any; maxValue?: any; ticks?: GoogleChartTicks | undefined; title?: string | undefined; viewWindow?: { max?: any; min?: any } | undefined } | undefined; legend: any; colors: string[] }> | undefined; chartId: any }) {
    return (
        <Chart
            chartType="ColumnChart"
            loader={<div>Loading Chart</div>}
            data={props.trendData}
            options={props.trendOptions}
        />
    )
}

export default CustomChart
